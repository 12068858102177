<template>
	<div>
		<div class="action-row" :class="{ overdue: isOverdue }" v-if="action">
			<div class="row-left">
				<div v-if="!props.isChecklist">
					<fa v-if="isEquipment" class="nav-icon" icon="tools" />
					<fa v-else class="nav-icon" icon="tasks" />
				</div>
				<div v-else>
					<div
						class="status-icon"
						:class="{
							danger: action.state == 'Critical',
							warning: action.state == 'Time-Critical',
						}"
					>
						<fa icon="exclamation-triangle" />
					</div>
				</div>

				<div
					class="description no-img-title"
					:class="{ overflow: !isChecklist }"
					@click="editAction()"
				>
					{{ action.description }}
				</div>
			</div>
			<div class="row-right">
				<div class="action-update">
					<div>
						{{ action.assignedPerson.name }}
					</div>

					<div
						v-if="action.status != 'Complete'"
						:class="{ overdue: isOverdue }"
					>
						{{
							new Date(action.assignedDate).toLocaleString(
								"en-US",
								options
							)
						}}
					</div>
					<div :class="{ complete: action.status == 'Complete' }">
						{{ action.status }}
					</div>
				</div>
				<div class="actions" v-if="!props.isChecklist">
					<div class="actions-icon-btn" @click="editAction()">
						<fa icon="pen" />
					</div>
					<div class="actions-icon-btn" @click="deleteAction()">
						<fa icon="trash" />
					</div>
				</div>
			</div>
		</div>
		<div class="alert" v-if="deleteActionAlert">
			<span v-if="!deletingAction">Are you sure you want to delete?</span>
			<button
				v-if="!deletingAction"
				class="btn btn-success"
				@click="clearDeleteActionAlert()"
			>
				Cancel
			</button>
			<button
				v-if="!deletingAction"
				class="btn btn-danger"
				@click="permanentlyDeleteAction()"
			>
				Delete
			</button>
			<PageLoading v-if="deletingAction" />
		</div>
	</div>
</template>

<script>
import { onMounted, ref, toRefs, computed } from "vue";
import { useRouter } from "vue-router";
import { projectFirestore } from "../../firebase/config";
import PageLoading from "../PageLoading.vue";

export default {
	name: "ActionListRow",
	components: { PageLoading },
	props: {
		isChecklist: {
			type: Boolean,
		},
		action: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const router = useRouter();
		const { action } = toRefs(props);
		const isEquipment = ref(false);
		const deleteActionAlert = ref(false);
		const deletingAction = ref(false);
		// Date/time formatting options
		let options = {
			year: "numeric",
			month: "short",
			day: "numeric",
		};
		onMounted(async () => {
			if (action.value.identifier) {
				isEquipment.value = true;
			}
		});
		const editAction = () => {
			const routeData = {
				name: "ActionDetail",
				params: {
					companyId: action.value.companyId,
					collection: isEquipment.value
						? "EquipmentAction"
						: "Action",
					id: action.value.id,
				},
			};

			if (props.isChecklist) {
				// Open in a new window
				let routeUrl = router.resolve(routeData).href;
				window.open(routeUrl, "_blank");
			} else {
				// Navigate in the same window
				router.push(routeData);
			}
		};
		// Your implementation here
		const deleteAction = () => {
			deleteActionAlert.value = true;
		};
		const permanentlyDeleteAction = async () => {
			// Your implementation here
			deletingAction.value = true;
			let collection = isEquipment.value ? "EquipmentAction" : "Action";
			const actionRef = projectFirestore
				.collection(collection)
				.doc(action.value.id);
			try {
				await actionRef.delete();
				deleteActionAlert.value = false;
				deletingAction.value = false;
			} catch (err) {
				console.error(err);
				deleteActionAlert.value = false;
				deletingAction.value = false;
			}
		};
		const clearDeleteActionAlert = () => {
			deleteActionAlert.value = false;
		};

		const isOverdue = computed(() => {
			let today = new Date();
			let dueDate = new Date(action.value.assignedDate);
			if (today > dueDate && action.value.status != "Complete") {
				return true;
			} else {
				return false;
			}
		});

		return {
			props,
			action,
			isEquipment,
			options,
			editAction,
			deleteAction,
			deleteActionAlert,
			permanentlyDeleteAction,
			clearDeleteActionAlert,
			deletingAction,
			isOverdue,
		};
	},
	components: { PageLoading },
};
</script>

<style lang="scss" scoped>
.overdue {
	border-left: 8px solid $rm-danger !important;
}
.action-row {
	display: flex;
	width: 100%;
	overflow: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	min-height: 47px;
	background-color: $white;
	border-radius: $border-radius;
	border: 1px solid $rm-light-grey;
	justify-content: space-between;
	align-items: center;
	padding: 5px 10px;

	@include respond(small) {
		min-height: 55px;
		padding: 10px 20px;
	}
	.row-left {
		display: inline-flex;
		justify-content: flex-start;
		align-items: center;
		max-width: 75%;

		.overflow {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			@include respond(small) {
				max-width: 20ch;
			}
			@include respond(medium) {
				max-width: 30ch;
			}
			@include respond(large) {
				max-width: 40ch;
			}
			@include respond(larger) {
				max-width: 68ch;
			}
		}
		.description {
			flex: 1;
			margin-left: 1em;
			padding-left: 1em;
			&:hover {
				cursor: pointer;
				color: $rm-pink;
			}
		}
	}
	.row-right {
		display: inline-flex;
		justify-content: flex-end;
		align-items: center;
		gap: 1em;
		font-size: 12px;
		margin-left: 1em;
		@include respond(small) {
			font-size: 14px;
		}
		@include respond(large) {
			font-size: 1em;
		}

		.action-update {
			display: flex;
			align-items: center;
			gap: 1em;
			font-size: 0.8em;
			font-weight: bold;
			* {
				background-color: $rm-light-grey;
				border-radius: $border-radius;
				padding: 0.5em;
				text-align: center;
				width: 15ch;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.complete {
				background-color: $rm-success;
				color: white;
			}
			.overdue {
				background-color: $rm-danger;
				color: white;
			}
		}
		.actions {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 1.5em;
			margin-left: 1em;
			.actions-icon-btn {
				color: $rm-grey;
				cursor: pointer;
				&:hover {
					// background-color: white;
					color: $rm-pink;
					// border-color: $rm-blue;
				}
			}
		}
	}
}
</style>
